<template>
  <div>
    <div class="product-gallery-box py-6">
      <div>
        <div class="product-gallery-box-head">
          <img
            src="~/assets/img/CentralStationCRM-Logo-Einfaches-Kontaktmanagement-CRM.png"
            alt=""
            width="130"
            height="60"
            class="product-gallery-box-head-img"
          >
          <div>
            <h1 class="title has-text-dark is-size-4 is-size-5-mobile mb-5">
              {{ $t('product-gallery.crm-title') }}
            </h1>
            <h2 class="subtitle has-text-primary is-size-6 mb-3">
              {{ $t('product-gallery.crm-sub-title') }}
            </h2>
          </div>
        </div>
        <div class="is-flex is-align-items-center pb-6">
          <div>
            <p>
              {{ $t('product-gallery.crm-desc') }}
            </p>
            <a class="button is-primary mt-3" :href="$t('product.crm')">
              {{ $t('product-gallery.crm-button') }}
            </a>
          </div>
        </div>
      </div>
      <div>
        <div class="product-gallery-box-head">
          <img
            src="~/assets/img/CentralPlanner_Logo.png"
            alt=""
            width="130"
            height="60"
            class="product-gallery-box-head-img"
          >
          <div>
            <h1 class="title has-text-dark is-size-4 is-size-6-mobile mb-5">
              {{ $t('product-gallery.cp-title') }}
            </h1>
            <h2 class="subtitle has-text-primary is-size-6 mb-3">
              {{ $t('product-gallery.cp-subtitle') }}
            </h2>
          </div>
        </div>
        <div class="is-flex is-align-items-center pb-6">
          <div>
            <p>
              {{ $t('product-gallery.cp-desc') }}
            </p>
            <a class="button is-primary mt-3" :href="$t('product.cp')">
              {{ $t('product-gallery.cp-button') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-gallery-box {
  display: flex;
  gap: 7%;
}

.product-gallery-box-head {
  display: flex;
  gap: 20px;
  align-items: center;
}

.product-gallery-box-head-img {
  width: 70px;
  height: 100%;
}

@media screen and (max-width: 950px) {
  .product-gallery-box {
    flex-direction: column;
    gap: 50px;
  }
}
</style>
